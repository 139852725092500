<template>
    <form ref="form" name="coampayForm" :action="requestUrl" accept-charset="euc-kr" method="post">
        <input type="hidden" name="mid" v-model="mid" />

        <input type="hidden" name="payGroup" value="OPG" />
        <input type="hidden" name="payType" value="CC" />
        <input type="hidden" name="payBrand" value="OCC" />

        <input type="hidden" name="buyItemnm" v-model="orderName" />
        <input type="hidden" name="buyReqamt" v-model="paymentAmount" />
        <input type="hidden" name="buyItemcd" value="" />
        <input type="hidden" name="buyerid" value="" />
        <input type="hidden" name="buyernm" v-model="buyerName" />
        <input type="hidden" name="buyerEmail" value="" />

        <input type="hidden" name="orderno" v-model="orderNo" />
        <input type="hidden" name="orderdt" v-model="orderDate" />
        <input type="hidden" name="ordertm" v-model="orderTime" />
        <input type="hidden" name="checkHash" v-model="checkHash" />

        <input type="hidden" name="rUrl" v-model="returnUrl" />
        <input type="hidden" name="rMethod" value="POST" />
    </form>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
export default {
    data() {
        return {
            // 결제정보
            mid: null,
            gateway: null,
            orderNo: this.$route.query.orderNo,
            orderDate: null,
            orderTime: null,
            orderName: this.$route.query.orderName,
            paymentAmount: +(this.$route.query.paymentAmount || 0),
            paymentMethod: "creditcard",
            buyerName: this.$route.query.buyerName,
            checkHash: null,
        };
    },
    mounted() {
        window.coampayComplete = (payload) => {
            window?.parent?.coampayComplete(payload);
            window?.opener?.coampayComplete(payload);
        };

        this.auth();
    },
    methods: {
        async auth() {
            const { paymentAmount, orderNo } = this.$data;
            const { mid, gateway } = await api.plugins.coampay.v2.config.get();
            const { orderDate, orderTime, checkHash } = await api.plugins.coampay.v2.generate({ paymentAmount, orderNo });

            this.mid = mid;
            this.gateway = gateway;
            this.checkHash = checkHash;
            this.orderDate = orderDate;
            this.orderTime = orderTime;

            this.$nextTick(() => {
                var form = this.$refs.form;

                if( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 )
                {
                    // 모바일 환경일때 처리
                }
                else{
                    // PC 환경일때 처리
                    var width = 500;
                    var height = 477;
                    var leftpos = screen.width / 2 - (width / 2);
                    var toppos = screen.height / 2 - (height / 2);
                    var feature = `width=${width}, height=${height}, toolbar=no, status=no, statusbar=no, menubar=no, scrollbars=no, resizable=no, left=${leftpos}, top=${toppos}`;

                    var modal = window.open('about:blank', "coampayPopup", feature);
                    if (modal == null) {
                        //팝업 차단여부 확인
                        alert("팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.");
                    }

                    form.target = "coampayPopup";
                }
                
                //인코딩 euc-kr 처리
                if (/edge/.test(window.navigator.userAgent.toLowerCase()) || /MSIE/.test(window.navigator.userAgent) || /Trident.*rv[ :]*11\./.test(window.navigator.userAgent)) {
                    var befCharset = document.charset;
                    // document.charset = "euc-kr";
                    window.document.charset = "utf-8";
                    // document.charset = befCharset;
                } else {
                    // this.$refs.form.acceptCharset = "euc-kr";
                    this.$refs.form.acceptCharset = "utf-8";
                }

                form.action = this.requestUrl;
                form.method = "post";
                form.submit();

                if (befCharset) document.charset = befCharset;
            });
        },
    },
    computed: {
        requestUrl(){
            if( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 ){
                // 모바일 환경일때 처리
                return `${this.gateway}/mobilepage/common/mainFrame.pay`;
            }
            else{
                return `${this.gateway}/paypage/common/mainFrame.pay`;
            }
        },
        returnUrl() {
            return `${window.location.origin}/api/plugins/coampay/v2/response`;
        },
        userAgent() {
            // return "WP"
            return (detect == "pc") ? "WP" : "WM";
        },
    },
};
</script>
